<template>

    <div>

        <v-container fluid>

            <v-form ref="form">
                <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                    <v-col cols="6" style="padding: 0px !important;">
                        <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -12px;">Company</v-subheader>
                    </v-col>
                    <v-col class="d-flex" align="left" cols="6" style="padding: 0px !important; margin-bottom: -12px;">
                        <v-checkbox
                        v-model="checkbox_accredited"
                        @change="tickCheckbox()"
                        color="csb_yellow"
                        >
                            <template v-slot:label>
                                <span style="font-size: 12px">The company I work for <br> is not on the list</span>
                            </template>
                        </v-checkbox>
                    </v-col>
                    
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-autocomplete 
                        autocomplete="off"
                        ref="companyInput"
                        :rules="[rules.required]"
                        :loading="isLoading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="companyInput" 
                        :items="companies" 
                        :search-input.sync="search"
                        item-text="company" 
                        item-value="company" 
                        label="Start typing to search company"
                        :disabled="checkbox_accredited"
                        >

                    
                    </v-autocomplete>
                </v-row>
                
            </v-form>
            
        </v-container>
        <br><br>
        <v-row  align="center" justify="space-around" class="bottom-nav">
            
            <v-col class="bottom-nav-item">
                <v-btn @click="handleNext()" depressed class="bottom-nav-btn">
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { getCompanies } from '@/api/calculator'
import { mapGetters } from 'vuex'


export default {
    computed: {

        ...mapGetters([
            'fb_messengerid',
        ]),
    },
    
    data: function() {
        return {

            companyInput: '',
            companyId: '',

            snackbar: false,
            text: 'Only images are accepted as signature.',
            timeout: 2000,

            all_companies: [],

            companies: [],

            isLoading: false,

            checkbox_accredited: false,

            search: null,

            //rules
            rules: {
                required: v => !!v || 'Required.',
            },
        }
    },

    watch: {
        search (val) {

            if(val == null || val.length < 3){
                this.companies = []
                return
            } else if(val.length === 3) {
                this.isLoading = true
                // Lazily load input items

                this.companies = this.all_companies.filter(i => i.company.toLowerCase().includes(val.toLowerCase()))
                this.isLoading = false
            }
        },
    },
  
    methods:{
        
        handleNext(){
            let valid = true
            if(this.checkbox_accredited == false){
                valid = this.$refs.form.validate()
            }

            if(valid){

                if(this.checkbox_accredited == true)
                    this.$router.push({ path: '/company-details/index' })
                else{
                    this.$data.companyId = this.getCompanyId()
                    this.$router.push({ path: '/calculator/index' })
                }

                this.$store.dispatch('user/company', this.$data)
                this.$store.dispatch('user/navVisibility', true) // show nav
            }
        },

        getCompanyId(){
            var name = this.companies.filter(i => i.company == this.companyInput )
            return name[0].sys_id
        },

        tickCheckbox(){
            this.companyInput = ''
            this.$refs.companyInput.reset()

            this.$store.dispatch('user/accreditedCompany', !this.checkbox_accredited) // show nav
        },

        getDepedCompanies(){
            getCompanies('ael').then(response => {
                
                this.all_companies = response.data.data
            }).catch((error) => {
                this.text = 'Error: ' + error
                this.snackbar = true 
                return []
            });
        },
        
    },
    mounted(){
        this.getDepedCompanies()
        if(this.fb_messengerid == '' ){ 
            var fbInfo = {
                fb_fname: this.$route.query.fb_fname, 
                fb_lname: this.$route.query.fb_lname, 
                fb_messengerid: this.$route.query.fb_messengerid, 
                fb_gender: this.$route.query.fb_gender, 
                fb_profilepic: this.$route.query.fb_profilepic
            }
        }
        this.$store.dispatch('user/fbInfo', fbInfo)
    }

}
</script>